@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.InstructorContainer {
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-wrap;
  position: relative;
  // > img {
  //   // align-self: center; // se lo tolgo l'immagine su ipad si stretcha
  //   height: 100%;
  // }
  @media screen and (min-width: 320px) {
    img {
      width: 100%;
    }
  }
  @media screen and (min-width: 768px) {
    min-height: 500px;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  img {
    @extend .image-fit;
  }
  &:nth-child(odd) {
    @extend .flex-direction-row-reverse;
  }
}
.Instructor {
  @media screen and (min-width: 320px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    width: 50%;
  }
  @media screen and (min-width: 1024px) {
    width: 30%;
  }
  z-index: 1;
  background: colors.$black;
  @extend .bsbb;
  padding: 50px 20px;
  color: colors.$white;
}

.Role {
  text-transform: uppercase;
  font-weight: 700;
}

.Name {
  text-transform: uppercase;
  padding: 20px 0;
  font-size: 30px;
  display: block;
  color: colors.$my-tan;
  @extend .orbitron;
}

.Description {
  display: block;
  font-weight: 700;
  margin-bottom: 20px;
}

.UlInstructor {
  li {
    padding: 5px 0;
  }
}
