@use '../../../../../styles/colors';
@use '../../../../../styles/mixin';

.Partners {
  @extend .display-flex;
  @extend .flex-direction-column;
  @extend .flex-justify-around;
  margin: 20px 0 80px 0;

  @media screen and (min-width: 480px) {
    @include mixin.flex-row;
  }
}

.LnkPartner {
  img {
    height: 50px;
    margin-bottom: 20px;
    @media screen and (min-width: 480px) {
      margin-bottom: 0;
    }
  }
}
