@use '../../../styles/colors';
@use '../../../styles/mixin';

.Feedbacks {
  background: colors.$my-dark;
  padding: 20px 0;
  width: 100%;
  margin: 40px 0 0 0;
}

.FeedbackTitle {
  // @extend .orbitron;
  color: colors.$white;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 20px;
  color: colors.$my-tan;
  @media screen and (min-width: 320px) {
    font-size: 8vw;
  }
  @media screen and (min-width: 768px) {
    font-size: 4vw;
  }
}

.SubTitle {
  text-align: center;
  color: colors.$white;
  text-transform: uppercase;
  padding-bottom: 30px;
  font-size: 10px;
}

.SliderFeedback {
  @extend .display-flex;
  @extend .flex-start;
  @extend .flex-direction-row;
  width: 100%;
  color: colors.$white;
  @extend .bsbb;
  padding: 0 20px;
  > img {
    @media screen and (min-width: 320px) {
      max-width: 100px;
    }
    @media screen and (min-width: 768px) {
      max-width: 200px;
    }
  }
  // > ul {
  //   width: 100%;
  //   @extend .display-flex;
  //   @extend .flex-direction-row;
  //   @extend .flex-justify-between;
  //   li {
  //     @extend .display-flex;
  //     @extend .flex-direction-column;
  //     @extend .flex-center-start;
  //     width: 100%;
  //     @extend .bsbb;
  //     @media screen and (min-width: 320px) {
  //       padding: 0 5px;
  //     }
  //     @media screen and (min-width: 768px) {
  //       padding: 0 20px;
  //     }
  //     img {
  //       @media screen and (min-width: 320px) {
  //         width: 100px;
  //       }
  //       @media screen and (min-width: 768px) {
  //         width: 200px;
  //       }
  //       @include border-radius(2px);
  //     }
  //   }
  // }
}

.Txt {
  color: colors.$white;
  // @media screen and (min-width: 320px) {
  //   @include calc('min-width', '100% - 120px');
  // }
  // @media screen and (min-width: 768px) {
  //   width: 50%;
  // }
  padding: 0 20px;
  > span {
    display: block;
  }
}

.Message {
  font-style: italic;
  font-size: 20px;
  // text-align: left;
  padding: 20px 0;
}

.Name {
  font-weight: 700;
  width: 100%;

  // border-bottom: 1px solid $my-tan;
  // margin-bottom: 20px;
}

.Course {
  width: 100%;
  b {
    font-weight: 500;
  }
}
