@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Logo {
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 20px;
  @extend .display-flex;
  @extend .flex-direction-column;
  height: 120px;
  width: 80px;
  font-family: 'Orbitron', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 10px;
  background: colors.$white;
  @extend .flex-center;
  @extend .bsbb;
  padding: 10px;
  z-index: colors.$zi-logo;
  text-decoration: none;
  img {
    width: 100%;
  }
}
