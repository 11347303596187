@use 'colors';
@use 'mixin';
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: colors.$white;
  color: colors.$my-dark;
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  font-size: 15px;
  font-family: 'Rajdhani', sans-serif;
  vertical-align: top;
  font-weight: 400;
  color: colors.$my-dark;
  border: 0;
  outline: none;
}
textarea {
  resize: none;
}

a,
button {
  @extend .trstn;
  &:hover {
    @extend .trstn;
  }
}

::selection {
  background-color: colors.$my-tan;
  color: colors.$white;
}

::-moz-selection {
  background-color: colors.$my-tan;
  color: colors.$white;
}

h1 {
  font-family: 'Orbitron', sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  word-break: break-all;
  margin-bottom: 20px;
  &:not(:first-of-type),
  &.mt {
    margin-top: 20px;
  }
}

h2 {
  font-family: 'Orbitron', sans-serif;
  font-size: 25px;
  text-transform: uppercase;
  margin-bottom: 20px;
  &:not(:first-of-type),
  &.mt {
    margin-top: 20px;
  }
}

input,
select {
  padding: 10px;
  background: colors.$white;
  @include mixin.border-radius(2px);
}
textarea {
  min-height: 200px;
}

/* View transitions */
// ::view-transition-old(root) {
//   animation: fade-and-scale-out 0.5s ease-in-out 1 forwards;
// }
// ::view-transition-new(root) {
//   animation: fade-and-scale-in 1s ease-in-out 1 forwards;
// }

// @keyframes fade-and-scale-in {
//   from {
//     opacity: 0;
//     // transform: scale(0);
//   }
//   to {
//     opacity: 1;
//     // transform: scale(1);
//   }
// }
// @keyframes fade-and-scale-out {
//   from {
//     opacity: 1;
//     // transform: scale(1);
//   }
//   to {
//     opacity: 0;
//     // transform: scale(0);
//   }
// }
// /* Second animation */
// @keyframes slide-in {
//   from {
//     opacity: 0;
//     transform: translateX(100%);
//   }
//   to {
//     opacity: 1;
//     transform: translateX(0);
//   }
// }
// @keyframes slide-out {
//   from {
//     opacity: 1;
//     transform: translateX(0);
//   }
//   to {
//     opacity: 0;
//     transform: translateX(-100%);
//   }
// }
