@use '../../../styles/colors';
@use '../../../styles/mixin';

.Announcements {
  position: fixed;
  cursor: pointer;
  top: 0;
  @media screen and (min-width: 320px) {
    left: 110px;
    @include mixin.calc('width', '100% - 220px');
  }
  @media screen and (min-width: 768px) {
    left: 150px;
    @include mixin.calc('width', '100% - 300px');
  }
  &:empty {
    display: none;
  }
  background: colors.$my-tan;
  z-index: colors.$zi-announcements;
  @extend .bsbb;
  padding: 10px;
  text-transform: uppercase;
  color: colors.$white;
  font-weight: 700;
  @include mixin.border-radiusd(0, 0, 5px, 5px);
  @extend .display-flex;
  @extend .flex-direction-column;
  span {
    text-align: center;
    // text-shadow: 0px 0px 5px $grey-900;
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
}
