@use 'colors';
@use 'mixin';

.common-description {
  @extend .bsbb;
  margin: 40px 0 0 0;
  padding: 0 20px;
  // padding: 50px 20px;
  &.black {
    background: colors.$black;
    color: colors.$white;
  }
  &.mb {
    margin-bottom: 20px;
  }
  strong {
    font-weight: 600;
  }
}
.common-title {
  padding-bottom: 20px;
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  color: colors.$my-dark;
  &:not(:first-of-type) {
    padding-top: 50px;
  }
}

.common-ul-dots {
  margin-top: 10px;
  list-style-type: circle;
  &.mb {
    margin-bottom: 20px;
  }
  li {
    margin-left: 20px;
  }
}

/* TXT COLORS */
.txt-red {
  color: colors.$red;
}
.txt-pink {
  color: colors.$pink;
}
.txt-purple {
  color: colors.$purple;
}
.txt-deep-purple {
  color: colors.$deep-purple;
}
.txt-indigo {
  color: colors.$indigo;
}
.txt-blue {
  color: colors.$blue;
}
.txt-light-blue {
  color: colors.$light-blue;
}
.txt-cyan {
  color: colors.$cyan;
}
.txt-teal {
  color: colors.$teal;
}
.txt-green {
  color: colors.$green;
}
.txt-light-green {
  color: colors.$light-green;
}
.txt-lime {
  color: colors.$lime;
}
.txt-yellow {
  color: colors.$yellow;
}
.txt-amber {
  color: colors.$amber;
}
.txt-orange {
  color: colors.$orange;
}
.txt-deep-orange {
  color: colors.$deep-orange;
}
.txt-brown {
  color: colors.$brown;
}
.txt-blue-grey {
  color: colors.$blue-grey;
}

/* BKG COLORS */
.bkg-red {
  background: colors.$red;
}
.bkg-pink {
  background: colors.$pink;
}
.bkg-purple {
  background: colors.$purple;
}
.bkg-deep-purple {
  background: colors.$deep-purple;
}
.bkg-indigo {
  background: colors.$indigo;
}
.bkg-blue {
  background: colors.$blue;
}
.bkg-light-blue {
  background: colors.$light-blue;
}
.bkg-cyan {
  background: colors.$cyan;
}
.bkg-teal {
  background: colors.$teal;
}
.bkg-green {
  background: colors.$green;
}
.bkg-light-green {
  background: colors.$light-green;
}
.bkg-lime {
  background: colors.$lime;
}
.bkg-yellow {
  background: colors.$yellow;
}
.bkg-amber {
  background: colors.$amber;
}
.bkg-orange {
  background: colors.$orange;
}
.bkg-deep-orange {
  background: colors.$deep-orange;
}
.bkg-brown {
  background: colors.$brown;
}
.bkg-blue-grey {
  background: colors.$blue-grey;
}

/* BORDER-LEFT COLORS */
.border-left-red {
  border-left: 10px solid colors.$red;
}
.border-left-pink {
  border-left: 10px solid colors.$pink;
}
.border-left-purple {
  border-left: 10px solid colors.$purple;
}
.border-left-deep-purple {
  border-left: 10px solid colors.$deep-purple;
}
.border-left-indigo {
  border-left: 10px solid colors.$indigo;
}
.border-left-blue {
  border-left: 10px solid colors.$blue;
}
.border-left-light-blue {
  border-left: 10px solid colors.$light-blue;
}
.border-left-cyan {
  border-left: 10px solid colors.$cyan;
}
.border-left-teal {
  border-left: 10px solid colors.$teal;
}
.border-left-green {
  border-left: 10px solid colors.$green;
}
.border-left-light-green {
  border-left: 10px solid colors.$light-green;
}
.border-left-lime {
  border-left: 10px solid colors.$lime;
}
.border-left-yellow {
  border-left: 10px solid colors.$yellow;
}
.border-left-amber {
  border-left: 10px solid colors.$amber;
}
.border-left-orange {
  border-left: 10px solid colors.$orange;
}
.border-left-deep-orange {
  border-left: 10px solid colors.$deep-orange;
}
.border-left-brown {
  border-left: 10px solid colors.$brown;
}
.border-left-blue-grey {
  border-left: 10px solid colors.$blue-grey;
}

p {
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
