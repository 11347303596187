@use '../../../styles/colors';
@use '../../../styles/mixin';

.LoaderBkg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(colors.$my-dark, 0.9);
  z-index: colors.$zi-page-loader;
  @extend .display-flex;
  @extend .flex-center;
}

.Logo {
  width: 100px;
  animation: pulse 2.5s infinite linear both;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
