@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Footer {
  @extend .display-flex;
  @extend .flex-direction-column;
}

.FooterLogo {
  width: 100%;
  @extend .display-flex;
  @extend .flex-center-start;
  line-height: 20px;
  margin: 20px 0 0 0;
  @extend .bsbb;
  img {
    width: 100px;
    margin: 0 20px;
  }
  @extend .flex-direction-column;
  @media screen and (min-width: 480px) {
    @include mixin.flex-row;
  }
}

.Txt {
  @extend .display-flex;
  @extend .flex-direction-column;
  span {
    font-size: 8px;
  }
  a {
    color: colors.$my-dark;
    &:hover {
      text-decoration: none;
    }
  }
}
