@use '../../../../../styles/colors';
@use '../../../../../styles/mixin';

.UlSocial {
  @extend .display-flex;
  @extend .flex-direction-row;
  width: 100%;
  background: colors.$grey-300;
  @extend .flex-center-end;
  border-top: 1px solid colors.$grey-400;
  border-bottom: 1px solid colors.$grey-400;
  margin: 40px 0 0 0;
}

.LnkSocial {
  @extend .display-flex;
  @extend .flex-center;
  @extend .bsbb;
  padding: 10px;
  width: 40px;
  height: 40px;
  text-decoration: none;
  &:hover {
    background: colors.$my-dark;
    color: colors.$my-red;
  }
}
