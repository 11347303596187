@use '../../../styles/colors';
@use '../../../styles/mixin';

.SliderContainer {
  @extend .bsbb;
  padding: 20px 0;
  margin: 40px 0 0 0;
  background: colors.$my-tan;
}

.SliderPhoto {
  @media screen and (min-width: 320px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    width: 200px !important;
    height: 200px !important;
  }
  img {
    width: 100%;
  }
}
