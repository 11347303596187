@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Nav {
  position: fixed;
  top: 0;
  right: 20px;
  @extend .display-flex;
  height: 120px;
  width: 80px;
  background: colors.$my-red;
  @extend .flex-center;
  z-index: colors.$zi-nav;
}

.Hamburger {
  color: colors.$white;
  font-size: 30px;
  margin-top: 50px;
}
