@use '../../../styles/colors';
@use '../../../styles/mixin';

.ContactForm {
  margin: 40px 0 0 0;
  @extend .bsbb;
  position: relative;
  background: url('../../../assets/backgrounds/pistol.jpg') no-repeat center
    center;
  background-size: cover;
}

.UlForm {
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-wrap;
  background: rgba(colors.$my-dark, 0.7);
  // margin: 40px 0 20px 0;
  > li {
    color: colors.$white;
    @extend .bsbb;
  }
  @media screen and (min-width: 320px) {
    padding: 10px;
    > li {
      width: 100%;
      &:first-of-type {
        padding: 0 0 10px 0;
      }
      &:not(:first-of-type) {
        padding: 10px 0 0 0;
      }
    }
  }
  @media screen and (min-width: 768px) {
    padding: 50px;
    > li {
      width: 50%;
      &:first-of-type {
        padding: 0 20px 0 0;
      }
      &:not(:first-of-type) {
        padding: 0 0 0 20px;
      }
    }
  }
}

.SubmitContainer {
  margin-top: 20px;
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-center-start;
}

.SubmitForm {
  background: colors.$my-tan;
  padding: 10px;
  height: 40px;
  min-width: 120px;
  @extend .display-flex;
  @extend .flex-center;
  @include mixin.border-radius(2px);
  text-transform: uppercase;
  color: colors.$my-dark;
  cursor: pointer;
  font-weight: 700;
  &[disabled] {
    cursor: not-allowed;
    color: colors.$white;
    @include mixin.opacity(0.5);
  }
}

.Error {
  // color: $my-red;
  margin-left: 20px;
  font-weight: 700;
  // padding: 10px;
  color: colors.$white;
}

.UlShare {
  @extend .display-flex;
  @extend .flex-direction-column;
  li {
    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }
}

.Privacy {
  margin-top: 10px;
  display: block;
  color: colors.$white;
  a {
    color: colors.$white;
    font-weight: bold;
  }
}
