@use '../../../styles/colors';
@use '../../../styles/social';
@use '../../../styles/mixin';

.ShareButton {
  display: inline-flex;
  @extend .flex-direction-row;
  @extend .flex-center-start;
  color: colors.$white;
  @include mixin.border-radius(2px);
  text-decoration: none;
  font-weight: 700;
  padding: 0 10px 0 0;
  @include mixin.border-radius(2px);
  overflow: hidden;
  width: 140px;
  span {
    width: 30px;
    height: 30px;
    padding: 10px;
    @extend .display-flex;
    @extend .flex-center;
    margin-right: 10px;
    font-size: 20px;
  }
  &.whatsapp {
    span {
      background: social.$whatsapp;
    }
    background: rgba(social.$whatsapp, 0.5);
    &:hover {
      background: social.$whatsapp;
    }
  }
  &.phone {
    span {
      background: colors.$my-red;
    }
    background: rgba(colors.$my-red, 0.5);
    &:hover {
      background: colors.$my-red;
    }
  }
  &.email {
    span {
      background: colors.$blue;
    }
    background: rgba(colors.$blue, 0.5);
    &:hover {
      background: colors.$blue;
    }
  }
}
