@use '../../../styles/colors';
@use '../../../styles/mixin';

.Banner {
  width: 100%;
  @extend .display-flex;
  @extend .flex-center;
  margin: 40px 0 0 0;
  background: url('../../../assets/backgrounds/security.jpg') no-repeat center
    center;
  background-size: cover;
  text-decoration: none;
}

.InsideBanner {
  width: 100%;
  height: 100%;
  background: rgba(colors.$my-dark, 0.5);
  @extend .bsbb;
  padding: 20px;
  width: 100%;
  @extend .display-flex;
  @extend .flex-center;
}

.In {
  // @extend .orbitron;
  color: colors.$white;
  width: 100%;
  height: 100%;
  @extend .display-flex;
  @extend .flex-center;
  @extend .flex-direction-column;
  text-transform: uppercase;
  background: rgba(colors.$my-dark, 0.5);

  min-height: 200px;
  @extend .bsbb;
  padding: 20px;
  text-align: center;
  @media screen and (min-width: 320px) {
    font-size: 4vw;
  }
  @media screen and (min-width: 768px) {
    font-size: 2vw;
  }
}

.Title {
  color: colors.$my-tan;
  padding-bottom: 20px;
  @media screen and (min-width: 320px) {
    font-size: 8vw;
  }
  @media screen and (min-width: 768px) {
    font-size: 4vw;
  }
}
