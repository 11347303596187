@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Menu {
  background: colors.$white;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: colors.$zi-menu;

  left: 0;
  top: -100vh;
  @extend .trstn;
  @extend .flex-direction-column;
  @extend .flex-center-start;
  padding-top: 120px;
  @extend .bsbb;
  @extend .display-flex;
  @include mixin.opacity(0);
}

.MenuIsOpen {
  @extend .trstn;
  top: 0;
  @include mixin.opacity(1);
}

.HomeDefense {
  @extend .orbitron;
  font-size: 50px;
  width: 80%;
  text-transform: uppercase;
}

.UlMenu {
  @extend .display-flex;
  @extend .flex-direction-column;
  width: 80%;
  margin-top: 20px;
}

.LnkMenu {
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  color: colors.$my-dark;
  text-transform: uppercase;
  font-weight: 700;
  padding: 5px;
  margin: 2px 0;
  position: relative;
  display: inline-block;
  overflow: hidden;
  line-height: 20px;
  span {
    font-style: italic;
    text-transform: capitalize;
    margin-left: 10px;
    font-size: 12px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: -100%;
    width: 100%;
    //height: 100%;
    @include mixin.calc('height', '100% + 10px');
    background: colors.$my-tan;
    @include mixin.transition(0.5s);
    z-index: -1;
  }
  &:hover {
    &:before {
      left: 100%;
    }
  }
}

.UlSocial {
  width: 80%;
  margin-top: 40px;
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-center-end;
  li {
    a {
      width: 30px;
      height: 30px;
      @include mixin.border-radius(2px);
      @extend .display-flex;
      @extend .flex-center;
      text-decoration: none;
      // background: rgba($my-light-tan, 0.5);
      color: colors.$my-red;
      &:hover {
        background: colors.$my-tan;
        color: colors.$my-dark;
      }
    }
    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }
}
